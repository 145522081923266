/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.example-form {
  min-width: 150px;
  max-width: 800px;
  width: 90%;
  margin: auto;
}

.example-full-width {
  max-width: 100%;
}

.example-section {
  margin: 12px 0;
}

.example-margin {
  margin: 0 12px;
}

ul {
  list-style-type: none;
  margin-top: 4px;
}

.sp-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn {
  min-width: 220px;
  min-height: 35px;
  background-color: #2489f6;
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
}

.btn:hover {
  cursor: pointer;
}

.btn:disabled {
  background-color: #144881;
}
